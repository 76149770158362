#loading-modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(128, 128, 128, 0.884);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* Ensure it's above other elements */
}