.topic-card {
    height: 20vh;
    margin-bottom: 3vh;
}

.topic-div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}